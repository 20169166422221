import React, {Component} from 'react';
import {signUp} from "../../store/actions/authActions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

class SignUp extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }
    handelChange = (e) => {
        this.setState({[e.target.id]:e.target.value})

    }
    handelSubmit = (e) => {
        e.preventDefault();
        this.props.signUp(this.state);
    }

    render() {

        const {auth, authError}=this.props;
        if(auth.uid) return <Redirect to='/'/>

        return (
            <div className="container">
                <form onSubmit={this.handelSubmit} className="white">
                    <h5 className="grey-text text-darken-3">Sign Up</h5>
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="firstName">firstName</label>
                        <input type="text" id="firstName" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="lastName">lastName</label>
                        <input type="text" id="lastName" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <button type="submit" className="btn pink lighten-1 z-depth-0">SignUp</button>
                    </div>
                </form>
                <div className="red-text center">
                    {authError ? <p>{authError}</p> :null}
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return {
         auth: state.firebase.auth,
        authError: state.auth.authError
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        signUp: (creds)=>{dispatch(signUp(creds))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SignUp);