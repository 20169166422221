const initState={
    projects:[
        {id: '1', title:'title1', content: 'gfgdgdfgdfgdfgdfgdf'},
        {id: '2', title:'title2', content: 'gfgdgdfgdfgdfgdfgdf'},
        {id: '3', title:'title3', content: 'gfgdgdfgdfgdfgdfgdf'}
    ]
}
const prjReducer = (state=initState,action)=>{
    if(action.type==='CREATE_PRJ'){
        return state;
    }else if(action.type==='CREATE_PRJ_ERR'){
       return state;
    }else{
        return state;
    }

}
export default prjReducer;