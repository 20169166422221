import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
/*import logo from './logo.svg';
import './App.css';*/
import Navbar from "./comps/layout/Navbar";
import PrjDetails from "./comps/projects/PrjDetails";

import Dashboard from "./comps/dashboard/Dashboard";
import SignIn from "./comps/auth/SignIn";
import SignUp from "./comps/auth/SignUp";
import CreatePrg from "./comps/projects/CreatePrg";

function App() {
      return (
        <BrowserRouter>

            <div className="App">
                <Navbar/>
                <Switch>
                    <Route exact path='/' component={Dashboard}/>
                    <Route path='/prj/:id' component={PrjDetails}/>
                    <Route path='/signin' component={SignIn}/>
                    <Route path='/signup' component={SignUp}/>
                    <Route path='/create' component={CreatePrg}/>
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default App;
