import React from 'react';
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {compose} from 'redux';
import {NavLink, Redirect} from "react-router-dom";
import moment from "moment";

const PrjDetails = (props) => {
    const {project,auth} = props;
    if(!auth.uid) return <Redirect to='/signin/'/>
    if (project){
        return (
            <div className="container section project-details">
                <div className="card z-depth-0">
                    <div className="card-content">
                        <span className="card-title">{props.project.title}</span>
                        <p>{props.project.content}</p>
                    </div>
                    <div className="card-action grey lighten-4 grey-text">
                        <div>{props.project.authorFirst} {props.project.authorLast}</div>
                        <div>{moment(props.project.createdAt.toDate()).calendar()}</div>
                    </div>
                    <div className="card-action  grey lighten-4 ">
                        <NavLink to="/">Назад</NavLink>
                    </div>
                </div>

            </div>

        );
    }else{
        return (
            <div className="container center">
                <p>Loading project...</p>
            </div>
        );
    }

};

const mapStateToProps = (state, ownProps)=>{
    const id = ownProps.match.params.id;
    const projectsState=state.firestore.data.projects;
    const project = projectsState ? projectsState[id]:null;
    return{
        project,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {collection: 'projects'}
    ])
)(PrjDetails);

