import React, {Component} from 'react';
import {connect} from "react-redux";
import {signIn} from "../../store/actions/authActions";
import {Redirect} from "react-router-dom";

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    handelChange = (e) => {
        this.setState({[e.target.id]:e.target.value})

    }
    handelSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        this.props.signIn(this.state)
    }

    render() {
const {authError,auth}=this.props;
        if(auth.uid) return <Redirect to='/'/>
        return (
            <div className="container">
                <form onSubmit={this.handelSubmit} className="white">
                <h5 className="grey-text text-darken-3">Log In</h5>
                <div className="input-field">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" onChange={this.handelChange}/>
                </div>
                <div className="input-field">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" onChange={this.handelChange}/>
                </div>
                <div className="input-field">
                    <button type="submit" className="btn pink lighten-1 z-depth-0">LogIn</button>
                </div>
                </form>
                <div className="red-text center">
                    {authError ? <p>{authError}</p> :null}
                </div>
            </div>
        );
    };
};
const mapStateToProps = (state) => {

    return {
        authError: state.auth.authError,
        auth: state.firebase.auth

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials)=>{dispatch(signIn(credentials))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SignIn);