const initState={
    authError: null
}
const authReducer = (state=initState,action)=>{
    if(action.type==='LOGIN_SUC'){
        return{
            ...state,authError: null
        }
    }else if(action.type==='LOGIN_ERR'){
        return{
            ...state,authError:'Login Failed'
        }
    }else if(action.type==='SIGNOUT_SUC'){
        return state;
    }else if(action.type==='NEWUSER_SUC'){
        return{
            ...state,authError: null
        }
    }else if(action.type==='NEWUSER_ERR'){
        return{
            ...state,
            authError: action.err.message
        }
    }else {
       // console.log('other type action',action);
        return state;
    }
}
export default authReducer;