import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyDsUuL_QHaSOZSLgtyfgQav0-IcILnRcec",
    authDomain: "modernjs-e9091.firebaseapp.com",
    databaseURL: "https://modernjs-e9091.firebaseio.com",
    projectId: "modernjs-e9091",
    storageBucket: "modernjs-e9091.appspot.com",
    messagingSenderId: "533123609752",
    appId: "1:533123609752:web:79b779495c9746fbf18ced",
    measurementId: "G-FWNVLZJB85"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default firebase;
export {db};