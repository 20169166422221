export const createPrj = (project) => {
    return (dispatch, getState, {getFirebase}) => {
        const profile = getState().firebase.profile;
        const authUid = getState().firebase.auth.uid;

        const firestore = getFirebase().firestore();

        firestore.collection('projects').add({
            ...project,
            authorFirst: profile.username,
            authorLast: profile.lastname,
            authorId: authUid,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_PRJ', project });
        }).catch(err => {
            dispatch({ type: 'CREATE_PRJ_ERR', project }, err);
        });
    }
};
