import React from "react";
import PrjSum from "./PrjSummary";
import {Link} from 'react-router-dom'

const PrjList = ({projects})=>{
    let prjOut=null;
if(projects!==undefined && projects!==null){
    prjOut = projects.map((project)=>{
        return (
            <Link to={'/prj/'+project.id}  key={project.id}>
            <PrjSum prj={project} key={project.id}/>
            </Link>
        )
    });
}

    return(
        <div className="prj-list section">
            {prjOut}
    </div>

    );
}

export default PrjList;