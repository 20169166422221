
export const signIn = (credentials)=>{
    return (dispatch,getState,{getFirebase})=>{

            const firebase = getFirebase();
            firebase.auth().signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            ).then(()=>{
                dispatch({type: 'LOGIN_SUC'});
            }).catch((err)=>{
                dispatch({type: 'LOGIN_ERR', err});
            })


    }
};

export const signOut = ()=>{
    return(dispatch,getState, {getFirebase})=>{
        const firebase=getFirebase();
        firebase.auth().signOut().then(()=>{
            dispatch({type: 'SIGNOUT_SUC', getState})
        }).catch(()=>{
            dispatch({type: 'SIGNOUT_ERR'})
        })
    }
};

export const signUp = (newUser)=>{
    return(dispatch,getState, {getFirebase,db})=>{
        const firebase = getFirebase();
        const createNewUser = ({ email, password, username,lastname,initials }) => {
            firebase.createUser(
                { email, password },
                { username, email,lastname,initials }
            ).then(()=>{
                dispatch({type: 'NEWUSER_SUC', newUser})
            }).catch((err)=>{dispatch({type: 'NEWUSER_ERR', err}) });
        }

        createNewUser({
            email: newUser.email,
            password: newUser.password,
            username: newUser.firstName,
            lastname:newUser.lastName,
            initials: newUser.firstName[0]+newUser.lastName[0]
        })
    }
};

/*
export const signUp = (newUser)=>{
    return(dispatch,getState, {firebase,db})=>{

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(()=>{
                return db.collection('users').add({
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    initials: newUser.firstName[0]+newUser.lastName[0]
                }).then(()=>{
                    dispatch({type: 'NEWUSER_SUC', newUser})
                }).catch((err)=>{dispatch({type: 'NEWUSER_ERR', newUser}) });

            }
        );




    }
};*/
