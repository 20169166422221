import React from "react";
import moment from "moment"

const PrjSum = ({prj})=>{
   return(

            <div className="card z-depth-0 prj-summary">
                <div className="card-content grey-text text-darken-3">
                    <span className="card-title">{prj.title}</span>
                    <p>{prj.authorFirst} {prj.authorLast}</p>
                    <p className="grey-text">{moment(prj.createdAt.toDate()).calendar()}</p>
                </div>
            </div>

    );
}

export default PrjSum;