import React, {Component} from 'react';
import {createPrj} from "../../store/actions/prjActions";
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

class CreatePrg extends Component {
    state = {
        title: '',
        content: ''
    }
    handelChange = (e) => {
        this.setState({[e.target.id]: e.target.value})

    }
    handelSubmit = (e) => {
        e.preventDefault();
        this.props.createProject(this.state);
        this.props.history.push('/');
    }

    render() {
        const {auth}=this.props;
        if(!auth.uid) return <Redirect to='/signin/'/>

        return (
            <div className="container">
                <form onSubmit={this.handelSubmit} className="white">
                    <h5 className="grey-text text-darken-3">Create</h5>
                    <div className="input-field">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="content">Content</label>
                        <textarea className="materialize-textarea" id="content" onChange={this.handelChange}/>
                    </div>
                    <div className="input-field">
                        <button type="submit" className="btn pink lighten-1 z-depth-0">Create</button>
                    </div>
                </form>
            </div>
        );
    };
}
const mapStateToProps = (state) => {

    return {
        auth: state.firebase.auth,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (prj)=>{dispatch(createPrj(prj))}
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreatePrg);