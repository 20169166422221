import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore,applyMiddleware} from "redux";
import rootReducer from "./store/reducers/rootReducer";
import {Provider} from "react-redux";
import thunk from 'redux-thunk'
import {ReactReduxFirebaseProvider,getFirebase} from "react-redux-firebase";
import {createFirestoreInstance,reduxFirestore} from "redux-firestore";
import firebase from './config/fbConfig';
import {db} from './config/fbConfig';
import 'firebase/auth';
import 'firebase/firestore';

const store=createStore(rootReducer,
           applyMiddleware(thunk.withExtraArgument({firebase,db,getFirebase})),
 );
const rrfConfig = {
    userProfile: "users123",
    useFirestoreForProfile: true,
};

 const rrfProps = {
       firebase,
      config: rrfConfig,
       dispatch: store.dispatch,
     createFirestoreInstance
 }
ReactDOM.render(


<Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
</Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
